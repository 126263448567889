import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      Design & Devloped  By{" "}
      <a
        className="footer__link"
        href="https://rsinfotechsys.com/"
        target="_blank"
        style={{ color: "#fdfdfd" }}
        rel="noreferrer"
      >
        Right Serve Infotech Systems Pvt. Ltd.
      </a>
    </footer>
  );
};

export default Footer;
