import React, { useContext, useEffect, useState, useRef } from "react";
import VideoContext from "../../context/VideoContext";
import "./Video.css";
import { Card, Modal, Button, Input, notification, Avatar, message } from "antd";
import Man from "../../assests/man.svg";
import VideoIcon from "../../assests/video.svg";
import VideoOff from "../../assests/video-off.svg";
import Msg_Illus from "../../assests/msg_illus.svg";
import Msg from "../../assests/msg.svg";
import ScreenShare from '../../assests/share_screen.svg';
import RecordIcon from '../../assests/record.svg'; // Add record icon
import StopRecordIcon from '../../assests/stop_record.svg'; // Add stop record icon
import { UserOutlined, MessageOutlined } from "@ant-design/icons";


import { socket } from "../../context/VideoState";

const { Search } = Input;

const Video = () => {
  const {
    call, callAccepted, myVideo, userVideo, stream, name, setName,
    callEnded, me, callUser, leaveCall, answerCall, sendMsg: sendMsgFunc,
    msgRcv, chat, setChat, userName, myVdoStatus, screenShare, fullScreen,
    handleScreenSharing, userVdoStatus, updateVideo, myMicStatus,
    userMicStatus, updateMic, 
    // isRecording,setIsRecording,mediaRecorder, setMediaRecorder,recordedChunks, setRecordedChunks, 
    // handleStartRecording,handleStopRecording
  } = useContext(VideoContext);

  

  const [sendMsg, setSendMsg] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRecording, setIsRecording] = useState(false); // State to track recording
  const [mediaRecorder, setMediaRecorder] = useState(null); // MediaRecorder instance
  const [recordedChunks, setRecordedChunks] = useState([]); // Recorded data chunks

  const dummy = useRef();

  useEffect(() => {
    if (dummy?.current) dummy.current.scrollIntoView({ behavior: "smooth" });
  }, [chat]);
  const screenStreamRef = useRef(null); // Reference for combined screen & audio stream


  return (
    <div className="grid">
      {stream ? (
        <div
          style={{ textAlign: "center" }}
          className="card"
          id={callAccepted && !callEnded ? "video1" : "video3"}
        >
          {/* Video and Avatar */}
          <div className="video-avatar-container">
            <video
              playsInline
              muted
              onClick={fullScreen}
              ref={myVideo}
              autoPlay
              className="video-active"
              style={{ opacity: `${myVdoStatus ? "1" : "0"}` }}
            />

            <Avatar
              style={{
                backgroundColor: "#116",
                position: "absolute",
                opacity: `${myVdoStatus ? "-1" : "2"}`,
              }}
              size={98}
              icon={!name && <UserOutlined />}
            >
              {name}
            </Avatar>
          </div>

          {/* Control Icons */}
          <div className="iconsDiv">
            {/* Mic Toggle */}
            <div className="icons" onClick={updateMic} tabIndex="0">
              <i className={`fa fa-microphone${myMicStatus ? "" : "-slash"}`} aria-hidden="true"></i>
            </div>

            {/* Screen Recording Toggle */}
            {/* {callAccepted && !callEnded && ( */}
            
            {/* )} */}
            {/* {callAccepted && !callEnded && ( */}
              <div
                className="icons"
                onClick={() => handleScreenSharing()}
                tabIndex="0"
              >
                <img src={ScreenShare} alt="share screen" />
              </div>
            {/* )} */}

            {/* Video Toggle */}
            <div className="icons" onClick={updateVideo} tabIndex="0">
              {myVdoStatus ? <img src={VideoIcon} alt="video on icon" /> : <img src={VideoOff} alt="video off icon" />}
            </div>
          </div>
        </div>
      ) : (
        <div className="bouncing-loader"><div></div><div></div><div></div></div>
      )}


      {callAccepted && !callEnded && userVideo && (
        <div className="card2" style={{ textAlign: "center" }} id="video2">
          <div className="video-avatar-container">
            <video
              playsInline
              ref={userVideo}
              onClick={fullScreen}
              autoPlay
              className="video-active"
              style={{ opacity: `${userVdoStatus ? "1" : "0"}` }}
            />
            <Avatar
              style={{
                backgroundColor: "#116",
                position: "absolute",
                opacity: `${userVdoStatus ? "-1" : "2"}`,
              }}
              size={98}
              icon={!(userName || call.name) && <UserOutlined />}
            >
              {userName || call.name}
            </Avatar>
          </div>
        </div>
      )}
    </div>
  );
};

export default Video;
